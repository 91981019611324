<template>
  <div v-if="hasResource('feature_management')">
    <div class="recommended">
      <div class="search-form">
        <el-button
          class="black-btn btn-l"
          @click="handleAdd"
          v-if="hasResource('feature_asset_add')"
          >新增</el-button
        >
        <el-button
          class="white-btn"
          @click="handleDel"
          v-if="hasResource('feature_asset_delete')"
          >批量删除</el-button
        >
        <div class="dis-fix">
          <el-form :inline="true" ref="form" :model="form" :rules="rules">
            <el-form-item label="内部ID" prop="number">
              <el-input
                v-model="form.number"
                placeholder="输入编号检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="外部ID" prop="asset_uid">
              <el-input
                v-model="form.asset_uid"
                placeholder="输入ID检索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="资产名称" prop="name">
              <el-input
                v-model="form.name"
                placeholder="输入名称检索"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="search">查询</span>
            <span class="clear-button" @click="cancel">清空</span>
          </div>
        </div>
      </div>
      <div class="tabData">
        <el-table
          header-cell-class-name="table-head"
          cell-class-name="table-cell"
          :data="tableData"
          ref="multipleTable"
          tooltip-effect="dark"
          row-key="id"
          class="data-table"
          v-loading="tableLoading"
          @selection-change="handleSelectionChange"
        >
          <div v-for="col in tableColumns" :key="col.prop">
            <el-table-column
              v-if="col.label === '复选框'"
              type="selection"
              header-align="center"
              align="center"
              :fixed="col.fixed"
              show-overflow-tooltip
              :width="col.width"
            ></el-table-column>
            <el-table-column
              v-else
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :align="col.align"
            >
              <template slot="header" v-if="col.prop == 'operation'">
                <div style="padding-left: 22px !important">操作</div>
              </template>
              <template slot-scope="{ $index, row }">
                <div v-if="col.prop == 'index'">{{ $index + 1 }}</div>
                <div
                  v-else-if="
                    col.prop == 'sort' && hasResource('feature_asset_sort')
                  "
                >
                  <i class="iconfont">&#xe663;</i>
                </div>
                <div v-else-if="col.prop == 'operation'" class="icon-btn">
                  <el-button
                    class="preview-color"
                    v-if="hasResource('feature_asset_delete')"
                    size="mini"
                    type="text"
                    @click="handledItemDetail(row)"
                    ><i class="iconfont">&#xe678;</i>删除</el-button
                  >
                </div>
                <div v-else-if="col.prop == 'feature_status'">
                  {{ row.feature_status ? "已配置完成" : "图片未配置" }}
                </div>
                <div v-else-if="col.prop == 'feature_preview'">
                  <img
                    v-if="row.feature_preview"
                    class="width-50 height-auto"
                    :src="row.feature_preview+ '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'"
                  />
                  <span v-else>暂无</span>
                </div>
                <div v-else-if="col.prop == 'preview'">
                  <img
                    v-if="row.preview"
                    class="width-50 height-auto"
                    :src="row.preview+ '?imageMogr2/thumbnail/x128/ignore-error/1/interlace/1'"
                  />
                  <span v-else>暂无</span>
                </div>
                <div v-else>{{ row[col.prop] }}</div>
              </template>
            </el-table-column>
          </div>
        </el-table>
        <!-- <el-pagination
          class="ta-r mg-t-10"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNumber"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination> -->
      </div>
      <!-- 删除 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleDel"
        width="458px"
        class="pop-dialog"
        :modal-append-to-body="false"
      >
        <span v-if="!isMultipleSelect" class="del-text">
          您将删除资产
          <span class="del-name">{{ name }}</span>
        </span>
        <span v-if="isMultipleSelect" class="del-text"
          >您将删除所选的多个资产</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="confirmDel"
            class="confirm-button"
            :loading="btnLoading"
            >确 定</el-button
          >
          <el-button @click="dialogVisibleDel = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import api from "../../../api/asset";
export default {
  data() {
    return {
      header: {
        url: "featured-assets",
      },
      tableColumns: [
        {
          prop: "select",
          label: "复选框",
          width: 130,
          align: "left",
          fixed: "left",
          type: "select",
        },
        { prop: "sort", label: "拖拽排序", width: 130, align: "left" },
        { prop: "index", label: "顺序", width: 130, align: "left" },
        { prop: "preview", label: "缩略图", width: 130, align: "left" },
        { prop: "origin_id", label: "内部ID", width: 130, align: "left" },
        { prop: "asset_uid", label: "外部ID", width: 200, align: "left" },
        { prop: "name", label: "资产名称", width: 260, align: "left" },
        { prop: "operation", label: "操作", align: "left", fixed: "right" },
      ],
      options: {
        status: [
          { name: "全部", value: null },
          { name: "图片未配置", value: 0 },
          { name: "已配置完成", value: 1 }, // 配置完成
        ],
      },
      rules: {
        number: [
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        asset_uid: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      form: {
        number: "",
        name: "",
        asset_uid: "",
        status: null,
      },
      tableLoading: false,
      selectTableData: [],
      tableData: [],
      allTableData: [],
      tableSort: [],
      dialogVisibleDel: false,
      isMultipleSelect: false,
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      category: null,
      name: null,
      btnLoading: false,
    };
  },
  methods: {
    init() {
      this.tableLoading = true;
      let data = {
        market: "US",
        limit: -1,
        // page: this.pageNumber,
        filter: {
          origin_id: this.form.number,
          asset_uid: this.form.asset_uid,
          name: this.form.name,
          feature_status: this.form.status,
        },
        search: "",
      };
      api.recAssetList(data).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 10200) {
          this.tableData = res.data.data.items;
          this.allTableData = res.data.data.items;
          this.total = parseInt(res.data.data.total);
          // if (this.tableData.length <= 0) {
          //   this.currentPage = 1;
          //   this.init();
          // }
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    tableRowDrop() {
      // 表格中需要实现行拖动，选中tr父级元素
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        direction: "vertical",
        onEnd({ newIndex, oldIndex }) {
          let length = (_this.pageNumber - 1) * _this.pageSize;
          const currRow = _this.allTableData.splice(oldIndex + length, 1)[0];
          _this.allTableData.splice(newIndex + length, 0, currRow);
          _this.tableSort = _this.allTableData;
          if (newIndex != oldIndex) {
            _this.motifySort();
          }
        },
      });
    },
    motifySort() {
      this.tableLoading = true;
      let sorts = {};
      for (let i = 0; i < this.tableSort.length; i++) {
        let item = this.tableSort[i];
        sorts[item.id] = i;
      }
      let data = {
        market: "US",
        sort: sorts,
      };
      api.sortRecAssets(data).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 10200) {
          this.init();
          this.$$success("操作成功!");
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    handleAdd() {
      this.$router.push({
        path: "/featured-item",
      });
    },
    handleDel() {
      if (this.selectTableData.length == 0) {
        this.$$error("请先勾选数据~");
        return;
      } else {
        this.dialogVisibleDel = true;
        this.isMultipleSelect = true;
      }
    },
    confirmDel() {
      this.btnLoading = true;
      let idArr = [];
      (this.selectTableData || []).forEach((element) => {
        idArr.push(element.id);
      });
      let data = {
        market: "US",
        ids: idArr,
      };
      api.delRecAssets(data).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 10200) {
          this.dialogVisibleDel = false;
          this.selectTableData = [];
          this.isMultipleSelect = false;
          this.init();
          this.$$success("操作成功!");
        } else {
          this.$$error(res.data.message);
        }
        setTimeout(() => {
          this.btnLoading = false;
        }, 1000);
      });
    },
    search() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.pageNumber = 1;
          this.init();
        }
      });
    },
    cancel() {
      this.form = {
        number: "",
        asset_uid: "",
        name: "",
        status: null,
      };
      this.pageNumber = 1;
      this.init();
    },
    handleSelectionChange(data) {
      this.selectTableData = data;
    },
    handledItemDetail(row) {
      this.handleOperationItem(row);
      this.dialogVisibleDel = true;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.init();
    },
    handleOperationItem(row) {
      this.selectTableData = [];
      this.selectTableData.push(row);
      this.name = row.name;
    },
  },
  created() {
    this.init();
  },
  mounted() {
    // 阻止默认行为
    document.body.ondrop = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };
    if (this.hasResource("feature_asset_sort")) {
      this.tableRowDrop();
    }
  },
  activated() {
    if (this.$route.meta.keepAlive) {
      this.init();
    }
  },
};
</script>
<style lang="less">
@import "index.less";
</style>
